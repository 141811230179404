// import { Home } from 'feather-icons-react/build/IconComponents';
import React, { Component, Suspense } from 'react'
import { Route, Routes, } from 'react-router-dom';
import Footer from './Footer/Footer';





const Navbar = React.lazy(() => import("./Navbar/NavBar"));

const Home = React.lazy(() => import('./Home'))
const About = React.lazy(() => import("./About"));
const Services = React.lazy(() => import("./Services"));
// const Project = React.lazy(() => import("./Project"));
// const News = React.lazy(() => import("./News"));

const Contact = React.lazy(() => import("./Contact"));
const Coverage = React.lazy(() => import("./Coverage"));
const ItSupport = React.lazy(() => import("./ItSupport"));
const WebDev = React.lazy(() => import("./WebDev"))
const AppDev = React.lazy(() => import("./AppDev"))



export default class Appa extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navItems: [
                { id: 1, idnm: "/", navheading: "Home" },
                { id: 2, idnm: "/about", navheading: "About" },
                { id: 3, idnm: "/service", navheading: "Services" },
                { id: 4, idnm: "/coverage", navheading: "Coverage" },
                // { id: 5, idnm: "/project", navheading: "Project" },
                { id: 6, idnm: "/itsupport", navheading: "IT Support" },
                // { id: 8, idnm: "/blog", navheading: "News" },
                { id: 7, idnm: "/contact", navheading: "Contact" },

            ],
            pos: document.documentElement.scrollTop,
            imglight: false,
            navClass: "",
        };
    }

    componentDidMount() {
        window.addEventListener("scroll", this.scrollNavigation, true);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.scrollNavigation, true);
    }

    scrollNavigation = () => {
        var scrollup = document.documentElement.scrollTop;
        if (scrollup > this.state.pos) {
            this.setState({ navClass: "nav-sticky", imglight: false });
        } else {
            this.setState({ navClass: "", imglight: false });
        }
    };

    //set preloader div
    PreLoader = () => {
        return (
            <div id="preloader">
                <div id="status">
                    <div className="spinner">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                    </div>
                </div>
            </div>
        );
    };
    render() {
        return (
            <React.Fragment>

                <Suspense fallback={this.PreLoader()}>

                    {/* fixed navbar */}
                    <Navbar
                        navItems={this.state.navItems}
                        navClass={this.state.navClass}
                        imglight={this.state.imglight}
                    />


                    {/* routes */}
                    <Routes>
                        <Route exact path='/' element={<Home />} />
                        <Route exact path='/about' element={<About />} />
                        <Route exact path='/contact' element={<Contact />} />
                        <Route exact path='/service' element={<Services />} />
                        <Route exact path='/itsupport' element={<ItSupport />} />
                        <Route exact path='/coverage' element={<Coverage />} />
                        <Route exact path='/web' element={<WebDev />} />
                        <Route exact path='/app' element={<AppDev />} />

                        {/* <Route exact path='/project' element={<Project />} /> */}
                        {/* <Route exact path='/blog' element={<News />} /> */}
                    </Routes>


                </Suspense>
                {/* fixed footer */}
                <Footer />

            </React.Fragment>
        )
    }
}
