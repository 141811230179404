import React, { Component } from "react";


//import style
import "./assets/css/pe-icon-7.css";
import "./assets/scss/themes.scss";
// import { Notifications } from 'react-push-notification';



const Appa = React.lazy(() => { import('./component/Appa') })

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        {/* <Notifications /> */}
        <Appa />
      </React.Fragment>
    );
  }
}

export default App;
