import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import FooterLink from "../Footer/Footer_link";

// Footer Image
import footer_image from "../../assets/images/hero-1-bg-img.png";

import logolight from "../../assets/images/logo-dark.png";
// Import Logo

//import icon
import FeatherIcon from "feather-icons-react";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      links: [
        {
          id: 1,
          title: "Address",
          child: [
            { title: "Registered Office:", link: "/" },
            { title: "harju", link: "/" },
            { title: "Releases", link: "/" },
            { title: "Press", link: "/" },
            { title: "Mission", link: "/" },
          ],
        },
        {
          id: 2,
          title: "Call us",
          child: [
            { title: "123-123-1234", link: "/" },
            // { title: "Popular", link: "/" },
            // { title: "Customers", link: "/" },
            // { title: "Features", link: "/" },
          ],
        },
        {
          id: 3,
          title: "Email us",
          child: [
            { title: "help@fibzio.com", link: "/" },
            // { title: "Support", link: "/" },
            // { title: "Customer Service", link: "/" },
            // { title: "Get Started", link: "/" },
            // { title: "Guide", link: "/" },
          ],
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        {/* Footer Start */}
        <footer
          className="footer p-3 pt-5"
          style={{ backgroundImage: "url(" + footer_image + ")", justifyContent: "center" }}
        >
          <Container className="m-auto">
            <Row className=" justify-content-around">
              <Col lg={2} >
                <div className="mauto">
                  <Link to="#">
                    <img src={logolight} alt="" className="mb-5" height="150" width={150} />
                  </Link>
                  {/* <p className="text-white-50 my-4">Cras ultricies mi eu turpis sit hendrerit fringilla vestibulum ante ipsum primis in faucibus ultrices posuere cubilia.</p> */}
                  {/* <Link to="#" className="text-white-70"><FeatherIcon className="icon mr-1" icon="instagram" />{" "} Join Us In Instagram</Link> */}


                </div>
              </Col>
              <Col lg={9} className=" p-0">
                <Row style={{ gap: "1rem" }} className=" d-flex justify-content-between">
                  {/* Render Footer Link */}
                  {/* {this.state.links.map((item, key) => (
                    <Col md={4} key={key}>
                      <h4 className="text-white f-22 font-weight-normal mb-3">
                        {item.title}
                      </h4>
                      <ul className="list-unstyled footer-sub-menu">
                        {item.child.map((linkItem, key) => (
                          <li key={key}>
                            <Link className="footer-link" to={linkItem.link}>
                              {linkItem.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </Col>
                  ))} */}
                  <Col lg={4} style={{ color: " rgba(255, 255, 255, 0.5)" }}>
                    <div>
                      <h3 className="pb-4">India Office</h3>
                      {/* <br /> */}
                      {/* <h5>Registered Address:</h5> */}
                      <div style={{ fontSize: '15px', width: "80%", textAlign: "justify" }}>2072189, Gali No-10,
                        Shyam Path ChandMari Road,
                        KankarBagh Patna,
                        Patna BR-800020 IN</div>

                    </div>

                  </Col>

                  <Col lg={4} style={{ color: " rgba(255, 255, 255, 0.5)" }} >
                    <div >
                      <h3 className="pb-4">Europe Division</h3>


                      <h5>Registered Address:</h5>
                      <div className="mb-3" style={{ fontSize: '15px', textAlign: "left" }}>Harijumaa, Kuusala vald,
                        Pudisoo kula,Mannimae/1,
                        74626, Estonia</div>

                      {/* <br /> */}
                      <h5>Tallinn Office:</h5>
                      <div style={{ fontSize: '15px', textAlign: "justify" }}>Kiriku 6, Tallinn
                        10130, Estonia
                        <br />
                        +1(347)3848137
                      </div>

                      {/* <br /> */}
                      {/* <h5 className="mt-3">India Office:</h5>
                      <div style={{ fontSize: '15px', textAlign: "justify" }}>2072189, Gali No-10,
                        Shyam Path ChandMari Road,
                        KankarBagh Patna,
                        Patna BR-800020 IN</div> */}

                      {/* <br /> */}
                    </div>
                  </Col>
                  <Col lg style={{ color: " rgba(255, 255, 255, 0.5)", alignItems: "flex-end" }}>

                    <h4>Contact Us</h4>

                    <br />
                    <h4>
                      Call Us
                    </h4>

                    <a href="tel:+1(347)3848137" className=" text-decoration-none" ><div className="mb-2"><i class="fa-solid fa-square-phone"></i>  +1(347)3848137</div></a>
                    {/* </Col>
                  <Col md={4} style={{ color: " rgba(255, 255, 255, 0.5)" }}> */}
                    <h4>
                      Email Us
                    </h4>
                    <a href="mailto:hello@fibzio.com" className="text-decoration-none"><div><i class="fa-solid fa-envelope"></i> hello@fibzio.com</div></a>

                    <h4 className="mt-3">Follow Us</h4>
                    <div style={{ display: 'flex', gap: "5px" }}>
                      {/* <p style={{ color: " rgba(255, 255, 255, 0.5)" }}><b>Join Us On</b></p> */}
                      <a href="https://www.linkedin.com/company/fibzio/" target='_blank' rel="noreferrer" className="menu-social-link" onClick={() => { window.open('https://www.linkedin.com/company/fibzio/', '_blank', 'noopener,noreferrer') }}><FeatherIcon icon="linkedin" className="icon-xs sw_1-5" /></a>


                      <a href={"https://fb.me/fibzio"} className="menu-social-link" onClick={() => { window.open('https://fb.me/fibzio', '_blank', 'noopener,noreferrer') }}><FeatherIcon icon="facebook" className="icon-xs sw_1-5" /></a>


                      <a href={"https://twitter.com/1Fibzio"} className="menu-social-link" onClick={() => { window.open('https://twitter.com/1Fibzio', '_blank', 'noopener,noreferrer') }}><FeatherIcon icon="twitter" className="icon-xs sw_1-5" /></a>


                      <a href={"https://www.instagram.com/fibzio/"} className="menu-social-link" onClick={() => { window.open('https://www.instagram.com/fibzio/', '_blank', 'noopener,noreferrer') }}><FeatherIcon icon="instagram" className="icon-xs sw_1-5" /></a>
                    </div>

                  </Col>
                </Row>
              </Col>
            </Row>

            {/* <Row className="mt-3" style={{ borderBottom: "1px solid grey" }}></Row> */}
            {/* Render Footer Link End */}
            <FooterLink />
          </Container>
        </footer>
        {/* Footer End */}
      </React.Fragment>
    );
  }
}

export default Footer;
